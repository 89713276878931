import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Helmet } from "react-helmet";
import './SiteMap.scss';

// import { useLocation } from "react-router-dom";
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"
import { useEffect } from 'react';
import AreaData from '../../Components/ServicesPage/AreaData'


export default function SiteMap({ Blogs, FaqList, WebSitePages }) {
    const location = useLocation()
    const [SiteMapToArray, setSiteMapToArray] = useState([]);


    useEffect(() => {
        if (location.pathname.split('/')[2] == "website" || location.pathname.split('/')[1] == "sitemaps" || location.pathname.split('/').length <= 2 && location.pathname.split('/')[1] == "sitemap") {
            setSiteMapToArray(WebSitePages)
        } else if (location.pathname.split('/')[2] == "solutions") {
            setSiteMapToArray(WebSitePages)
        } else if (location.pathname.split('/')[2] == "blog") {
            setSiteMapToArray(Blogs)
        } else if (location.pathname.split('/')[2] == "faq") {
            setSiteMapToArray(FaqList)
        } else if (location.pathname.split('/')[2] == "socialmedia") {
            setSiteMapToArray(WebSitePages)
        }
    }, [location.pathname]);

    return (
        <div className="SiteMap">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Anaheim Air Duct Cleaning | Site Map</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="Anaheim Air Duct Cleaning | Site Map" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="Anaheim Air Duct Cleaning | Site Map" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.anaheimairduct.org/sitemap" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Explore the comprehensive sitemap for Air Duct Cleaning. Find detailed links to our Air Duct Cleaninging, cleaning, inspection, and repair services to ensure your home's safety and efficiency." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Explore the comprehensive sitemap for Air Duct Cleaning. Find detailed links to our Air Duct Cleaninging, cleaning, inspection, and repair services to ensure your home's safety and efficiency." data-react-helmet="true" />
                <meta name="keywords" content="Air Duct Cleaninging, Air Duct Cleaning, Air Duct inspection, Air Duct repair, Fireplace maintenance, Creosote removal, Air Duct safety, Air Duct flue cleaning, Soot removal, Air Duct maintenance services" data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="Anaheim Air Duct Cleaning | Site Map" data-react-helmet="true" />
            </Helmet>
            <div className="SiteMapMenu">
                <a href="/">Website</a>
            </div>
            <div className="SiteMapDes">
                <div className='SiteMapDesBox'>
                    <a href="/">Home Page</a>
                    <a href="/contact">Contact Us</a>
                    <a href="/book">Book Air Duct Cleaning Appointment Online In Anaheim, California</a>
                    <a href="/services">Our Services</a>
                    <a href="/blog">Blog</a>
                    <a href="/faq">Faq</a>
                    <a href="/sitemap">Site Map</a>
                    <a href='/reviews'>Customer Reviews</a>

                    {/* Service */}
                    {AreaData.map((A, index) =>
                        <a href={A.Path} className="Header1BoxMenuBox">
                            {A.Title}
                        </a>
                    )}

                    {/* Blog */}
                    <h1>Blog</h1>
                    <a href="/blog/the_importance_of_dryer_vent_cleaning:_protecting_your_home_and_ensuring_efficiency_3">The Importance of Dryer Vent Cleaning: Protecting Your Home and Ensuring Efficiency</a>
                    <a href="/blog/the_importance_of_ac_duct_cleaning:_ensuring_clean_and_efficient_cooling_for_your_home_2">The Importance of AC Duct Cleaning: Ensuring Clean and Efficient Cooling for Your Home</a>
                    <a href="/blog/the_essential_guide_to_air_duct_maintenance:_ensuring_clean_and_efficient_airflow_in_your_home_1">The Essential Guide to Air Duct Maintenance: Ensuring Clean and Efficient Airflow in Your Home</a>
                    <a href="/blog/the_importance_of_air_duct_cleaning:_ensuring_a_healthy_and_efficient_home_in_Anaheim,_ca_0">The Importance of Air Duct Cleaning: Ensuring a Healthy and Efficient Home in Anaheim, CA</a>
                    {/* Faq */}
                    <h1>Faq</h1>
                    <a href="/faq/Why_is_regular_air_duct_cleaning_important_0">Why is regular air duct cleaning important In Anaheim, CA?</a>
                    <a href="/faq/How_often_should_I_have_my_air_ducts_cleaned_1">How often should I have my air ducts cleaned In Anaheim, CA?</a>
                    <a href="/faq/What_are_the_signs_that_my_air_ducts_need_cleaning_2">What are the signs that my air ducts need cleaning In Anaheim, CA?</a>
                    <a href="/faq/Can_air_duct_cleaning_help_with_allergies_3">Can air duct cleaning help with allergies In Anaheim, CA?</a>
                    <a href="/faq/Is_air_duct_cleaning_safe_for_my_HVAC_system_4">Is air duct cleaning safe for my HVAC system In Anaheim, CA?</a>
                    <a href="/faq/How_does_residential_air_duct_cleaning_differ_from_commercial_cleaning_5">How does residential air duct cleaning differ from commercial cleaning In Anaheim, CA?</a>
                    <a href="/faq/What_are_the_benefits_of_residential_air_duct_cleaning_6">What are the benefits of residential air duct cleaning In Anaheim, CA?</a>
                    <a href="/faq/Can_residential_air_duct_cleaning_lower_my_energy_bills_7">Can residential air duct cleaning lower my energy bills In Anaheim, CA?</a>
                    <a href="/faq/How_long_does_residential_air_duct_cleaning_take_8">How long does residential air duct cleaning take In Anaheim, CA?</a>
                    <a href="/faq/Should_I_prepare_my_home_before_the_air_duct_cleaning_service_9">Should I prepare my home before the air duct cleaning service In Anaheim, CA?</a>
                    <a href="/faq/What_does_regular_air_duct_maintenance_involve_10">What does regular air duct maintenance involve In Anaheim, CA?</a>
                    <a href="/faq/How_often_should_air_ducts_be_maintained_11">How often should air ducts be maintained In Anaheim, CA?</a>
                    <a href="/faq/Can_maintenance_prevent_ductwork_damage_12">Can maintenance prevent ductwork damage In Anaheim, CA?</a>
                    <a href="/faq/What_are_the_signs_that_my_air_ducts_need_maintenance_13">What are the signs that my air ducts need maintenance In Anaheim, CA?</a>
                    <a href="/faq/Can_air_duct_maintenance_improve_HVAC_efficiency_14">Can air duct maintenance improve HVAC efficiency In Anaheim, CA?</a>
                    <a href="/faq/What_is_included_in_HVAC_system_cleaning_15">What is included in HVAC system cleaning In Anaheim, CA?</a>
                    <a href="/faq/How_does_HVAC_system_cleaning_improve_indoor_air_quality_16">How does HVAC system cleaning improve indoor air quality In Anaheim, CA?</a>
                    <a href="/faq/How_often_should_HVAC_systems_be_cleaned_17">How often should HVAC systems be cleaned In Anaheim, CA?</a>
                    <a href="/faq/Can_HVAC_system_cleaning_reduce_energy_consumption_18">Can HVAC system cleaning reduce energy consumption In Anaheim, CA?</a>
                    <a href="/faq/Is_HVAC_system_cleaning_necessary_if_I_regularly_change_my_filters_19">Is HVAC system cleaning necessary if I regularly change my filters In Anaheim, CA?</a>
                    <a href="/faq/Why_is_dryer_vent_cleaning_important_20">Why is dryer vent cleaning important In Anaheim, CA?</a>
                    <a href="/faq/How_often_should_dryer_vents_be_cleaned_21">How often should dryer vents be cleaned In Anaheim, CA?</a>
                    <a href="/faq/What_are_the_signs_that_my_dryer_vent_needs_cleaning_22">What are the signs that my dryer vent needs cleaning In Anaheim, CA?</a>
                    <a href="/faq/Can_a_clogged_dryer_vent_increase_energy_bills_23">Can a clogged dryer vent increase energy bills In Anaheim, CA?</a>
                    <a href="/faq/Is_dryer_vent_cleaning_necessary_if_I_clean_the_lint_trap_regularly_24">Is dryer vent cleaning necessary if I clean the lint trap regularly In Anaheim, CA?</a>
                    <a href="/faq/What_is_the_purpose_of_an_air_duct_inspection_25">What is the purpose of an air duct inspection In Anaheim, CA?</a>
                    <a href="/faq/How_often_should_air_ducts_be_inspected_26">How often should air ducts be inspected In Anaheim, CA?</a>
                    <a href="/faq/What_can_I_expect_during_an_air_duct_inspection_27">What can I expect during an air duct inspection In Anaheim, CA?</a>
                    <a href="/faq/Can_an_inspection_detect_mold_in_my_ducts_28">Can an inspection detect mold in my ducts In Anaheim, CA?</a>
                    <a href="/faq/What_are_the_benefits_of_regular_air_duct_inspections_29">What are the benefits of regular air duct inspections In Anaheim, CA?</a>
                    <a href="/faq/Why_is_furnace_duct_cleaning_important_30">Why is furnace duct cleaning important In Anaheim, CA?</a>
                    <a href="/faq/How_often_should_furnace_ducts_be_cleaned_31">How often should furnace ducts be cleaned In Anaheim, CA?</a>
                    <a href="/faq/What_are_the_signs_that_my_furnace_ducts_need_cleaning_32">What are the signs that my furnace ducts need cleaning In Anaheim, CA?</a>
                    <a href="/faq/Can_furnace_duct_cleaning_improve_my_heating_system’s_efficiency_33">Can furnace duct cleaning improve my heating system’s efficiency In Anaheim, CA?</a>
                    <a href="/faq/Is_furnace_duct_cleaning_safe_for_my_HVAC_system_34">Is furnace duct cleaning safe for my HVAC system In Anaheim, CA?</a>

                </div>



            </div>
            <NavigatorPath />
        </div>
    )
}
